<template>
  <div v-if="checkModule" class="section-pin">
    <form @submit.prevent.stop="onCheck">
      <label>PIN Check</label>
      <div class="input-area">
        <input v-model="pin" type="text" placeholder="Search PIN" />
        <span
          class="material-icons reset-pin"
          @click="reset()"
          v-if="this.pin.length"
          >close</span
        >
        &nbsp;
        <button><i class="material-icons">search</i></button>
      </div>
    </form>
    <ul>
      <li v-for="client in clients" :key="client.email">
        {{ client.nama }}
        <small
          ><i>({{ client.email }})</i></small
        >
      </li>
    </ul>
  </div>
</template>

<script>
import AppConfig from "@/common/config/app.config.json";
import { request, util } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "PINCheck",
  mixins: [request, util, loader],
  data() {
    return {
      pin: "",
      clients: [],
    };
  },
  methods: {
    onCheck: function () {
      if (this.pin === "") {
        this.$swal("Error", "PIN belum dimasukan", "error");
        return;
      }

      this.loading();
      this.API.post(this.URL.modules.pin.check, {
        pin: this.pin,
      })
        .then(({ data }) => {
          this.clients = data.data;
        })
        .catch((error) => {
          let err = this.handleCatchAxios(null, error, true);
          this.$swal("Error", err, "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },

    reset: function () {
      this.clients = [];
      this.pin = "";
    },
  },
  computed: {
    checkModule() {
      return AppConfig.modules.includes("pinCheck");
    },
  },
};
</script>

<style scoped lang="scss">
.section-pin {
  padding: 0 1em 0 1em;
  form {
    padding: 0;
    label {
      font-weight: bold;
    }
  }
  .input-area {
    display: flex;
    position: relative;
    input {
      border-radius: 6px !important;
    }
    .reset-pin {
      color: lightgray;
      position: absolute;
      right: 2em;
      margin-top: 0.5em;
      display: block;
      cursor: pointer;
    }
    button {
      border-radius: 0.4em !important;
      padding: 8px;
    }
  }
  ul {
    margin-top: 0.5em;
    list-style-type: decimal;
    padding-left: 1em;
  }
}
</style>
