<template>
  <div class="d-flex justify-content-center align-items-center">
    <template v-if="status === 1">
      <div class="blob green" :class="pulse ? 'animation-green' : ''"></div>
      <span>Connected</span>
    </template>
    <template v-else-if="status === 2">
      <div class="blob orange" :class="pulse ? 'animation-orange' : ''"></div>
      <span>Connection Timeout</span>
    </template>
    <template v-else>
      <div class="blob red" :class="pulse ? 'animation-red' : ''"></div>
      <span class="text-danger"><b>Connection Error !!!</b></span>
    </template>
  </div>
</template>

<script>
import { request, alert, waktu, rwSound } from "@/common/mixins/mix_helper";
import { mapGetters } from "vuex";
import { check_connection } from "@/common/config";

export default {
  name: "PulseConnection",
  mixins: [request, alert, waktu, rwSound],
  data() {
    return {
      counter: 0,
      status: 1, // 1 (connect) | 2 (timeout) | 3 (error)
      pulse: false,
    };
  },
  computed: {
    ...mapGetters(["getVuexGeneral"]),
  },
  methods: {
    checkingDiff() {
      if (typeof check_connection !== "undefined" && !check_connection) {
        console.warn("pass check connection");
        this.$store.dispatch(
          "printDemo",
          "NetworkStatus : pass connection by config"
        );
        return;
      }
      this.pulse = true;
      setTimeout(() => {
        const epochCurrent = this.mixEpoch();
        const epochDiff =
          parseInt(epochCurrent) -
          parseInt(this.getVuexGeneral._server_time.time);
        if (epochDiff > 20) {
          if (this.counter < 3) {
            this.mixSound(1, "net-tm");

            this.status = 2;
            this.counter++;

            this.mixToast("Network Timeout", {
              duration: 8000,
              style: {
                background: "var(--orange)",
              },
            });
          } else {
            this.mixSound(1, "net-dc");

            this.status = 3;

            this.mixToast("Network error", {
              duration: 8000,
              style: {
                background: "var(--danger)",
              },
            });
          }
        } else {
          if (this.counter) {
            this.status = 1;
            this.counter = 0;

            this.mixToast("You're connected now", {
              duration: 8000,
              style: {
                background: "var(--success)",
              },
            });
          }
        }
        this.pulse = false;
      }, 2000);
    },
  },
  created() {
    this.$crontab.addJob({
      name: "connectionStat",
      interval: {
        seconds: "/10", // sisanya 2s di checking diff untuk animasi pulse
      },
      job: this.checkingDiff,
    });
  },
};
</script>

<style scoped lang="scss">
.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-black 2s infinite;

  &.red {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  }

  &.orange {
    background: rgba(255, 121, 63, 1);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  }

  &.green {
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  }
}

.animation {
  &-red {
    animation: pulse-red 2s infinite;
  }
  &-orange {
    animation: pulse-orange 2s infinite;
  }
  &-green {
    animation: pulse-green 2s infinite;
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
</style>
