<template>
  <div class="blank-room">
    <img :src="image" alt="blank" />
    <p>Please select a conversation to start messaging</p>
  </div>
</template>

<script>
export default {
  name: "BlankRoom",
  data() {
    return {
      image: "img/svg/online.svg",
    };
  },
};
</script>
