<template>
  <li :id="message.time">
    <div
      class="parent--container my--container qcw-group"
      :class="message.date ? 'contain-date' : ''"
    >
      <div class="qcw-comment-container qcw-comment--text comment--me">
        <!-- if contain date -->
        <div class="qcw-comment-date" v-if="message.date">
          <div>{{ message.date }}</div>
        </div>

        <div class="qcw-comment comment--me comment--parent comment--last">
          <div class="qcw-avatar">
            <img :src="agentImage" alt="rw" />
          </div>
          <div
            class="qcw-comment__message"
            :class="
              message.status === 'sent' && message.deleted !== '1'
                ? 'hover-effect'
                : ''
            "
          >
            <div
              class="tip"
              style="
                border-left-color: rgb(255, 255, 255);
                border-right-color: transparent;
              "
            ></div>
            <span class="qcw-comment__username qcw-comment__username--hide"
              >Personal</span
            >

            <!--icon more option message-->
            <i @click="openMsgOption()" class="qcw-comment__more"
              ><img
                :src="more"
                alt="x"
                style="width: 16px; margin-top: -11px; margin-left: 4px"
            /></i>
            <transition name="fade">
              <div
                class="qcw-comment__more--menu more-kanan"
                v-if="message.uuid === uuidCurrentOpen"
              >
                <ul>
                  <!--<li>Reply</li> -->
                  <li
                    v-if="message.status === 'sent' && message.deleted !== '1'"
                    class="icon-trash"
                    @click="$emit('removeMessage', message)"
                  >
                    Delete
                  </li>
                </ul>
              </div>
            </transition>
            <div class="comment-text" style="z-index: 10">
              <div>
                <span
                  v-if="message.tipe === 'text'"
                  class="qcw-comment__content"
                  v-html="message.message"
                ></span>
              </div>
              <div v-if="message.tipe === 'image'" class="qcw-comment__content">
                <a :href="mixGetImage(message.message)" target="_blank">
                  <img
                    style="width: 100px"
                    :src="mixGetImage(message.message)"
                    :alt="message.message"
                  />
                </a>
              </div>
              <div v-if="message.tipe === 'file'" class="qcw-comment__content">
                <svg class="qc-icon mt-0">
                  <use xlink:href="#rw-file-download"></use>
                </svg>
                <a :href="mixGetFiles(message.message)" target="_blank"
                  >Download</a
                >
              </div>
            </div>
            <span class="qcw-comment__time" v-if="message.status === 'sent'">
              {{ mixEpochToHours(message.time) }}
            </span>
            <div>
              <div class="qcw-comment__state qcw-comment__state--read">
                <svg class="qc-icon" v-if="message.status === 'sent'">
                  <use xlink:href="#rw-check"></use>
                </svg>
                <svg
                  style="width: 12px; height: 25px"
                  class="rw-spin qc-icon"
                  v-if="message.status === 'pending'"
                >
                  <use xlink:href="#rw-spin"></use>
                </svg>
                <svg
                  class="qc-icon retry"
                  @click="retry()"
                  v-if="message.status === 'failed'"
                >
                  <use xlink:href="#rw-retry"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { waktu, cloudStorage } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "RightPartMessage",
  mixins: [waktu, cloudStorage, loader],
  props: {
    message: {
      required: true,
    },
    agentImage: {
      required: true,
    },

    /**
     * --------------------------------------------------------------
     * for flag open btn more message
     * --------------------------------------------------------------
     */
    uuidCurrentOpen: {
      required: true,
    },
  },
  data() {
    return {
      more: "./img/png/more.png",
    };
  },
  methods: {
    retry: function () {
      this.loading();
      setTimeout(() => {
        this.loading(false);
        this.$swal("Oke", "Coming soon ya gaess :-]", "success");
      }, 1000);
    },

    /**
     * --------------------------------------------------------------
     * when click open icon more
     * --------------------------------------------------------------
     */
    openMsgOption() {
      if (this.uuidCurrentOpen !== this.message.uuid) {
        this.$store.dispatch("setUuidMessageMoreOpened", this.message.uuid);
      } else {
        if (this.uuidCurrentOpen !== null) {
          this.$store.dispatch("setUuidMessageMoreOpened", null);
        } else {
          this.$store.dispatch("setUuidMessageMoreOpened", this.message.uuid);
        }
      }
    },
  },
};
</script>

<style scoped>
.retry {
  cursor: pointer;
}
.more-kanan {
  padding: 0;
  width: 0;
  height: 0;
  top: -4.5rem !important;
  left: -5rem;
}
.qcw-group.parent--container.my--container,
.qcw-group.parent--container.my--container .qcw-comment {
  margin-top: 0.2rem;
}
</style>
