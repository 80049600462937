<script>
import { waktu } from "@/common/mixins/mix_helper";

export default {
  name: "BotPartBubble",
  mixins: [waktu],
  props: {
    message: {
      required: true,
    },
  },
};
</script>

<template>
  <li :id="message.time">
    <div
      class="parent--container qcw-group"
      :class="message.date ? 'contain-date' : ''"
    >
      <div class="qcw-comment-container qcw-comment--text qcw-comment--cust">
        <div class="qcw-comment comment--parent comment--last">
          <div class="qcw-avatar">
            <img src="/img/rw/bot.png" alt="bot" />
          </div>
          <div class="qcw-comment__message" :class="message.tipe">
            <span class="qcw-comment__username">Bot</span>
            <div class="tip" />
            <div class="comment-text">
              <span class="qcw-comment__content" v-html="message.message" />
            </div>
            <span class="qcw-comment__time" style="color: rgb(151, 151, 151)">
              <span>{{
                message.tipe === "agent-close"
                  ? mixEpochToFullDate(message.time)
                  : mixEpochToHours(message.time)
              }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<style scoped lang="scss">
.alert {
  margin-bottom: 0 !important;
}
.qcw-comment-container {
  --botColor: #d9edbf;
}
.qcw-comment__message {
  color: rgb(102, 102, 102);
  background-color: var(--botColor) !important;
}

.tip {
  border-left-color: transparent;
  border-right-color: var(--botColor) !important;
}
.agent-close {
  background-color: var(--danger) !important;
  color: white !important;
  .tip {
    border-left-color: transparent;
    border-right-color: var(--danger) !important;
  }
  .qcw-comment__time {
    right: -12em !important;
  }
}
</style>
