<template>
  <li class="qcw-load-more qcw-load-more-btn" @click="loadMoreMessage">
    <span>Load More</span>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import AppConfig from "@/common/config/app.config.json";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "BtnLoadMoreMessage",
  mixins: [loader],
  methods: {
    /**
     * --------------------------------------------------------------
     * request all message with draw
     * DrawIncrease : menaikan draw
     * --------------------------------------------------------------
     */
    loadMoreMessage: function () {
      this.$store.dispatch("Draw", { type: "increase" });
      this.loading();
      let paramAllMessage = {
        threadid: this.getAllVuexAgent._dialogActive.id,
        draw: this.getAllVuexAgent._drawMessage.value,
        lastmsgid: this.getAllVuexAgent._drawMessage.lastmsgid,
        isLoadMore: true,
      };
      this.$store
        .dispatch("staffGetAllMessage", paramAllMessage)
        .then((res) => {
          /**
           * --------------------------------------------------------------
           * decrease jika record length 0
           * give an alert for agent
           * --------------------------------------------------------------
           */
          if (res.data.record.length === 0) {
            this.$swal({
              title: "Opss....",
              html: "All messages have been displayed.",
              icon: "warning",
            });
            this.$store.dispatch("Draw", { type: "decrease" });
          }
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
  computed: {
    ...mapGetters(["getChat", "getAllVuexAgent"]),

    /**
     * --------------------------------------------------------------
     * tampilkan load more button
     * if chat >= jml limit & bukan last message
     * --------------------------------------------------------------
     */
    showBtnMore: function () {
      let chatLength = this.getChat.length;
      return (
        chatLength >= AppConfig.limitMessage &&
        !this.getAllVuexAgent._drawMessage.last
      );
    },
  },
};
</script>

<style scoped>
.qcw-load-more.qcw-load-more-btn {
  background-color: #46ccfd;
}
</style>
