<template>
  <li :id="message.time">
    <div
      class="parent--container qcw-group"
      :class="message.date ? 'contain-date' : ''"
    >
      <div class="qcw-comment-container qcw-comment--text qcw-comment--cust">
        <!-- if contain date -->
        <div class="qcw-comment-date" v-if="message.date">
          <div>{{ message.date }}</div>
        </div>

        <div class="qcw-comment comment--parent comment--last">
          <div class="qcw-avatar">
            <img :src="clientImage" alt="rw" />
          </div>
          <div
            class="qcw-comment__message"
            :class="
              message.actor !== 'staff' && message.deleted === '0'
                ? 'hover-effect'
                : ''
            "
            style="color: rgb(102, 102, 102)"
            :style="{ 'background-color': setBackgroundColorBubble(message) }"
          >
            <div
              class="tip"
              :style="{
                'border-left-color': 'transparent',
                'border-right-color': setBackgroundColorBubble(message),
              }"
            ></div>
            <span class="qcw-comment__username"
              >{{ message.actor === "staff" ? "agent : " : "" }}
              {{ toHTML(message.name) }}
            </span>
            <i @click="openMsgOption()" class="reply-btn">
              <img
                :src="more"
                alt="x"
                style="width: 16px; margin-top: -2px; margin-left: 4px"
              />
            </i>
            <transition name="fade">
              <div
                class="qcw-comment__more--menu more-kiri"
                v-if="message.uuid === uuidCurrentOpen"
              >
                <ul>
                  <!--<li>Reply</li> -->
                  <li
                    v-if="message.actor !== 'staff' && message.deleted === '0'"
                    class="icon-trash"
                    @click="$emit('removeMessage', message)"
                  >
                    Delete
                  </li>
                </ul>
              </div>
            </transition>

            <div class="comment-text">
              <div>
                <!-- message here -->
                <span
                  v-if="
                    message.tipe === 'text' || message.tipe === '1st-message'
                  "
                  class="qcw-comment__content"
                  v-html="message.message"
                ></span>
              </div>
              <div v-if="message.tipe === 'image'" class="qcw-comment__content">
                <a :href="mixGetImage(message.message)" target="_blank">
                  <img
                    :src="mixGetImage(message.message)"
                    :alt="message.message"
                    class="img-thumbnail"
                  />
                </a>
              </div>
              <div v-if="message.tipe === 'file'" class="qcw-comment__content">
                <a :href="mixGetFiles(message.message)" target="_blank">
                  <svg class="qc-icon mt-0">
                    <use xlink:href="#rw-file-download"></use>
                  </svg>
                  <a :href="mixGetFiles(message.message)" target="_blank"
                    >Download</a
                  >
                </a>
              </div>
            </div>
            <span class="qcw-comment__time" style="color: rgb(151, 151, 151)">
              {{ mixEpochToHours(message.time) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { waktu, cloudStorage, util } from "@/common/mixins/mix_helper";
import { mapGetters } from "vuex";

export default {
  name: "LeftPartMessage",
  mixins: [waktu, cloudStorage, util],
  props: {
    message: {
      required: true,
    },
    clientImage: {
      required: true,
    },
    /**
     * --------------------------------------------------------------
     * for flag open btn more message
     * --------------------------------------------------------------
     */
    uuidCurrentOpen: {
      required: true,
    },
  },
  data() {
    return {
      more: "./img/png/more.png",
    };
  },
  computed: {
    ...mapGetters(["get_all"]),
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * this is just for testing cron message
     * --------------------------------------------------------------
     */
    hapusMessage(message) {
      this.$store.dispatch("removeMessage", message);
      this.$store.dispatch("removeMessageStaffLog", message);
    },

    /**
     * --------------------------------------------------------------
     * set background color bubble
     * --------------------------------------------------------------
     */
    setBackgroundColorBubble(message) {
      if (message.actor === "staff") {
        let colors = this.get_all._agentBgColor;
        let color = "#C7D36F";
        if (typeof colors[message.staffid] !== "undefined") {
          color = colors[message.staffid];
        }
        return color + " !important";
      }
    },
    /**
     * --------------------------------------------------------------
     * when click open icon more
     * --------------------------------------------------------------
     */
    openMsgOption() {
      if (this.uuidCurrentOpen !== this.message.uuid) {
        this.$store.dispatch("setUuidMessageMoreOpened", this.message.uuid);
      } else {
        if (this.uuidCurrentOpen !== null) {
          this.$store.dispatch("setUuidMessageMoreOpened", null);
        } else {
          this.$store.dispatch("setUuidMessageMoreOpened", this.message.uuid);
        }
      }
    },
  },
};
</script>

<style scoped>
.qcw-comment__message {
  color: rgb(102, 102, 102);
  /*background: rgb(232, 232, 232);*/
}
.img-thumbnail {
  height: 84px;
}
.more-kiri {
  padding: 0;
  right: 0;
  top: -3rem !important;
  height: 0;
  width: 0;
}
.qcw-comment__more--menu .more-kiri {
  margin-left: 50% !important;
}
.more-kiri ul {
  margin-left: 100%;
  position: initial;
}
</style>
