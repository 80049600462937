import { staffCookies } from "@/common/mixins/mix_cookies";
import { waktu } from "@/common/mixins/mix_helper";
export const agentMessage = {
  methods: {
    mixGenerateMessage: function (dinamisField) {
      let Cookies = staffCookies.data().cookies.get();

      if (
        typeof Cookies !== "undefined" &&
        typeof Cookies.staff !== "undefined" &&
        typeof Cookies.isLogin !== "undefined" &&
        typeof Cookies.staff.id !== "undefined" &&
        typeof Cookies.threadOpen !== "undefined" &&
        typeof Cookies.threadOpen.id !== "undefined" &&
        // typeof Cookies.threadOpen.fbid !== "undefined" &&
        Cookies.isLogin
      ) {
        let uid = () => {
          let str = Math.random().toString(36).substring(2);
          let epoch = Math.round(Date.now() / 1000);
          return str + "." + epoch;
        };
        let defaultField = {
          id: parseInt(Cookies.staff.id),
          time: waktu.methods.mixEpoch(),
          name: Cookies.staff.name,
          tipe: "text",
          uuid: uid(),
          actor: "staff",
          status: "pending",
          staffid: parseInt(Cookies.staff.id), // for manipulate append
          message: "message default mixin",
          threadid: Cookies.threadOpen.id,
          is_reply: true,
        };
        return Object.assign(defaultField, dinamisField);
      }
      return false;
    },
  },
};
