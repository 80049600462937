var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"id":_vm.message.time}},[_c('div',{staticClass:"parent--container qcw-group",class:_vm.message.date ? 'contain-date' : ''},[_c('div',{staticClass:"qcw-comment-container qcw-comment--text qcw-comment--cust"},[(_vm.message.date)?_c('div',{staticClass:"qcw-comment-date"},[_c('div',[_vm._v(_vm._s(_vm.message.date))])]):_vm._e(),_c('div',{staticClass:"qcw-comment comment--parent comment--last"},[_c('div',{staticClass:"qcw-avatar"},[_c('img',{attrs:{"src":_vm.clientImage,"alt":"rw"}})]),_c('div',{staticClass:"qcw-comment__message",class:_vm.message.actor !== 'staff' && _vm.message.deleted === '0'
              ? 'hover-effect'
              : '',staticStyle:{"color":"rgb(102, 102, 102)"},style:({ 'background-color': _vm.setBackgroundColorBubble(_vm.message) })},[_c('div',{staticClass:"tip",style:({
              'border-left-color': 'transparent',
              'border-right-color': _vm.setBackgroundColorBubble(_vm.message),
            })}),_c('span',{staticClass:"qcw-comment__username"},[_vm._v(_vm._s(_vm.message.actor === "staff" ? "agent : " : "")+" "+_vm._s(_vm.toHTML(_vm.message.name))+" ")]),_c('i',{staticClass:"reply-btn",on:{"click":function($event){return _vm.openMsgOption()}}},[_c('img',{staticStyle:{"width":"16px","margin-top":"-2px","margin-left":"4px"},attrs:{"src":_vm.more,"alt":"x"}})]),_c('transition',{attrs:{"name":"fade"}},[(_vm.message.uuid === _vm.uuidCurrentOpen)?_c('div',{staticClass:"qcw-comment__more--menu more-kiri"},[_c('ul',[(_vm.message.actor !== 'staff' && _vm.message.deleted === '0')?_c('li',{staticClass:"icon-trash",on:{"click":function($event){return _vm.$emit('removeMessage', _vm.message)}}},[_vm._v(" Delete ")]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"comment-text"},[_c('div',[(
                  _vm.message.tipe === 'text' || _vm.message.tipe === '1st-message'
                )?_c('span',{staticClass:"qcw-comment__content",domProps:{"innerHTML":_vm._s(_vm.message.message)}}):_vm._e()]),(_vm.message.tipe === 'image')?_c('div',{staticClass:"qcw-comment__content"},[_c('a',{attrs:{"href":_vm.mixGetImage(_vm.message.message),"target":"_blank"}},[_c('img',{staticClass:"img-thumbnail",attrs:{"src":_vm.mixGetImage(_vm.message.message),"alt":_vm.message.message}})])]):_vm._e(),(_vm.message.tipe === 'file')?_c('div',{staticClass:"qcw-comment__content"},[_c('a',{attrs:{"href":_vm.mixGetFiles(_vm.message.message),"target":"_blank"}},[_c('svg',{staticClass:"qc-icon mt-0"},[_c('use',{attrs:{"xlink:href":"#rw-file-download"}})]),_c('a',{attrs:{"href":_vm.mixGetFiles(_vm.message.message),"target":"_blank"}},[_vm._v("Download")])])]):_vm._e()]),_c('span',{staticClass:"qcw-comment__time",staticStyle:{"color":"rgb(151, 151, 151)"}},[_vm._v(" "+_vm._s(_vm.mixEpochToHours(_vm.message.time))+" ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }