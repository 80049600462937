<script>
import { request, alert } from "@/common/mixins/mix_helper";
import AppConfig from "@/common/config/app.config.json";

export default {
  name: "Tags",
  mixins: [request, alert],
  props: {
    threadid: {
      required: true,
    },
  },
  data() {
    return {
      model: {
        tag: null,
        id: null,
      },
      tags: [],
      tagsMaster: [],
      tagsResult: [],
      tagSelected: {},
    };
  },
  methods: {
    onFindTags(e) {
      const arrow = ["ArrowDown", "ArrowUp"];
      if (arrow.includes(e.key)) {
        const tagResultLength = this.tagsResult.length;
        if (tagResultLength > 0) {
          const selectedExist = Object.keys(this.tagSelected).length;
          if (!selectedExist) {
            this.tagSelected = this.tagsResult[0];
            this.tagSelected["index"] = 0;
          } else {
            const indexNew = this.tagSelected.index + 1;
            switch (e.key) {
              case "ArrowDown":
                if (tagResultLength > indexNew) {
                  this.tagSelected = this.tagsResult[indexNew];
                  this.tagSelected["index"] = indexNew;
                }
                break;

              case "ArrowUp":
                if (tagResultLength >= indexNew) {
                  const indexDecrement = indexNew - 2;
                  if (indexDecrement >= 0) {
                    this.tagSelected = this.tagsResult[indexDecrement];
                    this.tagSelected["index"] = indexDecrement;
                  }
                }
                break;
            }
            this.model = {
              tag: this.tagSelected["name"],
              id: this.tagSelected["id"],
            };
          }
        }
      } else if (e.key === "Escape") {
        this.model.id = null;
        this.model.tag = null;
        this.tagsResult = [];
        this.tagSelected = {};
      } else {
        this.tagSelected = {};
        this.tagsResult = [];
        if (this.model.tag.length >= 2) {
          this.tagsResult = this.tagsMaster.filter((e) =>
            e.name.toLowerCase().includes(this.model.tag.toLowerCase())
          );
        }
      }
    },
    onChooseResult(e) {
      this.model.id = e.id;
      this.model.tag = e.name;
      this.tagsResult = [];
      this.onAdd();
    },
    onAdd() {
      this.API.post(this.URL.tags.create, {
        tagid: this.model.id,
        tagname: this.model.tag,
        id: this.threadid,
      })
        .then(() => {
          this.fetchTags();
          this.mixToast(`Tag <b>${this.model.tag}</b> added successfully.`, {
            style: {
              background: "var(--success)",
            },
          });
          this.model.id = null;
          this.model.tag = null;
        })
        .catch(({ response }) => {
          this.mixToast(response.data.message, {
            style: {
              background: "var(--danger)",
            },
          });
        });
    },
    onRemove(e) {
      this.API.post(this.URL.tags.remove, {
        tagid: e.id,
      })
        .then(() => {
          this.fetchTags();
          this.mixToast(`Tag <b>${e.name}</b> remove successfully.`);
        })
        .catch(() => {
          this.mixToast(`Tag <b>${e.name}</b> remove failed.`, {
            style: {
              background: "var(--danger)",
            },
          });
        });
    },
    fetchTags() {
      this.API.get(this.URL.tags.list, `?id=${this.threadid}`)
        .then(({ data }) => {
          this.tags = data;
        })
        .catch((err) => {
          console.warn("Tags fetch failed ", err);
        });
    },
    fetchTagMaster() {
      this.API.get(this.URL.tags.master)
        .then(({ data }) => {
          this.tagsMaster = data;
        })
        .catch(() => {
          this.tagsMaster = [];
        });
    },
  },
  computed: {
    ModuleOn() {
      return AppConfig.modules.includes("Tags");
    },
  },
  mounted() {
    this.fetchTags();
    this.fetchTagMaster();
  },
  watch: {
    threadid() {
      this.fetchTags();
    },
  },
};
</script>

<template>
  <div class="px-3 py-0 w-100" v-if="ModuleOn">
    <form @submit.stop.prevent="onAdd" class="p-0">
      <label class="font-weight-bold">Tags</label>
      <div class="input-area position-relative">
        <input
          type="text"
          placeholder="Search and add tag"
          v-model="model.tag"
          @keyup.stop.prevent="onFindTags"
        />
        <ul
          class="position-absolute bg-light w-100 mt-1 rounded"
          id="searchResult"
          v-if="tagsResult.length > 0"
        >
          <li
            class="curp px-2 pt-1"
            :class="{
              selected: index === tagSelected.index,
            }"
            v-for="(tag, index) in tagsResult"
            :key="tag.id"
            @click="onChooseResult(tag)"
          >
            {{ tag.name }}
          </li>
        </ul>
      </div>
    </form>

    <div class="mt-2 d-flex w-100 flex-wrap gap-2" id="tags">
      <div class="badge badge-info mx-0" v-for="i in tags" :key="i.id">
        {{ i.name }} (<span class="curp text-danger" @click="onRemove(i)"
          >x</span
        >)
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
div#tags {
  gap: 0.1em;
}
div.badge {
  min-width: auto;
  width: auto;
}
.curp {
  cursor: pointer;
}
ul#searchResult {
  min-height: 1em;
  max-height: 10em;
  overflow-y: auto;

  li.selected {
    background: var(--cyan);
    color: white;
  }
}
ul#searchResult > li:hover {
  background: var(--cyan);
  color: var(--white);
}
</style>
