<template>
  <div class="app-chat-area relative">
    <!-- blank rooms -->
    <blank-room v-if="blankRoom"></blank-room>

    <!-- if staff select conversation -->
    <div
      v-else
      class="qcw-container qcw-container--wide"
      style="display: block"
    >
      <div class="qcw-chat-wrapper">
        <div class="qcw-main">
          <v-emoji-picker
            v-if="showEmoji"
            @select="selectEmoji"
            :emojisByRow="6"
            :emojiWithBorder="false"
          ></v-emoji-picker>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            id="qiscus-sdk-sprite"
          >
            <symbol id="ic-attachment" viewBox="0 0 448 512">
              <path
                d="M364.2 83.8C339.8 59.39 300.2 59.39 275.8 83.8L91.8 267.8C49.71 309.9 49.71 378.1 91.8 420.2C133.9 462.3 202.1 462.3 244.2 420.2L396.2 268.2C407.1 257.3 424.9 257.3 435.8 268.2C446.7 279.1 446.7 296.9 435.8 307.8L283.8 459.8C219.8 523.8 116.2 523.8 52.2 459.8C-11.75 395.8-11.75 292.2 52.2 228.2L236.2 44.2C282.5-2.08 357.5-2.08 403.8 44.2C450.1 90.48 450.1 165.5 403.8 211.8L227.8 387.8C199.2 416.4 152.8 416.4 124.2 387.8C95.59 359.2 95.59 312.8 124.2 284.2L268.2 140.2C279.1 129.3 296.9 129.3 307.8 140.2C318.7 151.1 318.7 168.9 307.8 179.8L163.8 323.8C157.1 330.5 157.1 341.5 163.8 348.2C170.5 354.9 181.5 354.9 188.2 348.2L364.2 172.2C388.6 147.8 388.6 108.2 364.2 83.8V83.8z"
              ></path>
            </symbol>
            <symbol id="ic-close" viewBox="0 0 20 20">
              <path
                id="path0_fill"
                d="M 19.6022 19.6022C 20.1326 19.072 20.1326 18.212 19.6022 17.6819L 11.9204 9.99988L 19.6023 2.31818C 20.1326 1.78773 20.1326 0.928053 19.6023 0.397595C 19.072 -0.132532 18.2121 -0.132532 17.6818 0.397595L 10 8.07962L 2.31819 0.397595C 1.78788 -0.132532 0.928042 -0.132532 0.397732 0.397595C -0.132577 0.928053 -0.132577 1.78773 0.397732 2.31818L 8.07957 9.99988L 0.397753 17.6819C -0.132557 18.212 -0.132557 19.072 0.397753 19.6022C 0.928063 20.1326 1.78788 20.1326 2.31821 19.6022L 10 11.9205L 17.6818 19.6022C 18.2121 20.1326 19.0719 20.1326 19.6022 19.6022Z"
              ></path>
            </symbol>
            <symbol id="ic-chevron-down" viewBox="0 0 1792 1792">
              <path
                d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z"
              ></path>
            </symbol>
            <symbol id="ic-chevron-left" viewBox="0 0 1792 1792">
              <path
                d="M1427 301L896 832l531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19L429 877q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
              ></path>
            </symbol>
            <symbol id="ic-chevron-right" viewBox="0 0 1792 1792">
              <path
                d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
              ></path>
            </symbol>
            <symbol id="ic-image" viewBox="0 0 512 512">
              <path d="M368 224a48 48 0 1 0 0-96 48 48 0 0 0 0 96z"></path>
              <path
                d="M452 64H60a28 28 0 0 0-28 28.3v327.4A28 28 0 0 0 60 448h392a28 28 0 0 0 28-28.3V92.3A28 28 0 0 0 452 64zM348.9 261.7c-3-3.5-7.6-6.2-12.8-6.2-5.1 0-8.7 2.4-12.8 5.7L304.6 277c-3.9 2.8-7 4.7-11.5 4.7-4.3 0-8.2-1.6-11-4.1l-4.3-4.1-53.8-58.2a22 22 0 0 0-33.5.3L64 368.2V107.7c1-6.8 6.3-11.7 13.1-11.7h357.7c6.9 0 12.5 5.1 12.9 12l.3 260.4-99.1-106.7z"
              ></path>
            </symbol>
            <symbol id="ic-chat-buble" viewBox="0 0 24 24">
              <path
                d="M2 2a1 1 0 0 0-1 1v13c0 .5.4 1 1 1h2l2.8 3.3c.4.4 1 .4 1.3 0L11 17h11c.6 0 1-.4 1-1V3c0-.5-.4-1-1-1H2zm3.2 4l-.2.2v.6l.2.2h12.6l.2-.2v-.6l-.2-.2H5.2zm0 3l-.2.2v.6l.2.2h12.6l.2-.2v-.6l-.2-.2H5.2zm0 3l-.2.2v.6c0 .1 0 .2.2.2h8.6l.2-.2v-.6c0-.1 0-.2-.2-.2H5.2z"
                fill-rule="evenodd"
              ></path>
            </symbol>
            <symbol id="ic-clock" viewBox="0 0 24 24">
              <path
                d="M16.3 21l2 2 .8-.7-2-2a9 9 0 1 0-10.3 0l-2 2 .7.7 2.1-2.1a9 9 0 0 0 8.7 0zM12 21a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-8V7h1v7H8v-1h4zM6.9 3.7a1 1 0 0 0-1.4-.2L3.9 4.7a1 1 0 0 0-.2 1.4l.5.6-.6.9-.7-.9A2 2 0 0 1 3.3 4l1.6-1.2a2 2 0 0 1 2.8.3l.5.7-1 .5-.3-.5zM20.3 6c.3-.4.2-1-.2-1.4l-1.6-1.2a1 1 0 0 0-1.4.2l-.4.5a10 10 0 0 0-1-.5l.6-.7c.7-.8 2-1 2.8-.3l1.6 1.2c.9.7 1 2 .4 2.8l-.7.9a10 10 0 0 0-.6-.9l.5-.6z"
                fill-rule="evenodd"
              ></path>
            </symbol>
            <symbol id="ic-phone" viewBox="0 0 18 18">
              <path
                d="M3.6 7.8c1.4 2.8 3.8 5.1 6.6 6.6l2.2-2.2c.3-.3.7-.4 1-.2 1.1.4 2.3.6 3.6.6.6 0 1 .4 1 1V17c0 .6-.4 1-1 1A17 17 0 0 1 0 1c0-.6.4-1 1-1h3.5c.6 0 1 .4 1 1 0 1.2.2 2.4.6 3.6.1.3 0 .7-.2 1L3.6 7.8z"
                fill-rule="evenodd"
              ></path>
            </symbol>
            <symbol id="ic-user" viewBox="0 0 1792 1792">
              <path
                d="M1600 1405q0 120-73 189.5t-194 69.5H459q-121 0-194-69.5T192 1405q0-53 3.5-103.5t14-109T236 1084t43-97.5 62-81 85.5-53.5T538 832q9 0 42 21.5t74.5 48 108 48T896 971t133.5-21.5 108-48 74.5-48 42-21.5q61 0 111.5 20t85.5 53.5 62 81 43 97.5 26.5 108.5 14 109 3.5 103.5zm-320-893q0 159-112.5 271.5T896 896 624.5 783.5 512 512t112.5-271.5T896 128t271.5 112.5T1280 512z"
              ></path>
            </symbol>
            <symbol id="ic-smiley" viewBox="0 0 512 512">
              <path
                d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256.3 331.8C208.9 331.8 164.1 324.9 124.5 312.8C112.2 309 100.2 319.7 105.2 331.5C130.1 390.6 188.4 432 256.3 432C324.2 432 382.4 390.6 407.4 331.5C412.4 319.7 400.4 309 388.1 312.8C348.4 324.9 303.7 331.8 256.3 331.8H256.3zM226.5 231.6C229.8 230.5 232 227.4 232 224C232 206.1 225.3 188.4 215.4 175.2C205.6 162.2 191.5 152 176 152C160.5 152 146.4 162.2 136.6 175.2C126.7 188.4 120 206.1 120 224C120 227.4 122.2 230.5 125.5 231.6C128.7 232.7 132.3 231.6 134.4 228.8L134.4 228.8L134.6 228.5C134.8 228.3 134.1 228 135.3 227.6C135.1 226.8 136.9 225.7 138.1 224.3C140.6 221.4 144.1 217.7 148.3 213.1C157.1 206.2 167.2 200 176 200C184.8 200 194.9 206.2 203.7 213.1C207.9 217.7 211.4 221.4 213.9 224.3C215.1 225.7 216 226.8 216.7 227.6C217 228 217.2 228.3 217.4 228.5L217.6 228.8L217.6 228.8C219.7 231.6 223.3 232.7 226.5 231.6V231.6zM377.6 228.8C379.7 231.6 383.3 232.7 386.5 231.6C389.8 230.5 392 227.4 392 224C392 206.1 385.3 188.4 375.4 175.2C365.6 162.2 351.5 152 336 152C320.5 152 306.4 162.2 296.6 175.2C286.7 188.4 280 206.1 280 224C280 227.4 282.2 230.5 285.5 231.6C288.7 232.7 292.3 231.6 294.4 228.8L294.4 228.8L294.6 228.5C294.8 228.3 294.1 228 295.3 227.6C295.1 226.8 296.9 225.7 298.1 224.3C300.6 221.4 304.1 217.7 308.3 213.1C317.1 206.2 327.2 200 336 200C344.8 200 354.9 206.2 363.7 213.1C367.9 217.7 371.4 221.4 373.9 224.3C375.1 225.7 376 226.8 376.7 227.6C377 228 377.2 228.3 377.4 228.5L377.6 228.8L377.6 228.8z"
              ></path>
            </symbol>
            <symbol id="ic-image-attachment" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M 9 9C 10.6567 9 12 7.65686 12 6C 12 4.34314 10.6567 3 9 3C 7.34326 3 6 4.34314 6 6C 6 7.65686 7.34326 9 9 9ZM 11 6C 11 7.10455 10.1045 8 9 8C 7.89551 8 7 7.10455 7 6C 7 4.89545 7.89551 4 9 4C 10.1045 4 11 4.89545 11 6Z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M 2.49414 0C 1.1167 0 0 1.24371 0 2.77789L 0 17.2221C 0 18.7562 1.1167 20 2.49414 20L 17.5059 20C 18.8835 20 20 18.7562 20 17.2221L 20 2.67212C 20 1.19635 18.926 0 17.6011 0L 2.49414 0ZM 2.49414 1.11115C 1.66772 1.11115 0.997559 1.85742 0.997559 2.77789L 0.997559 13.8889L 3.42456 11.7316C 4.3252 10.9309 5.66895 10.8868 6.62036 11.6267L 8.41357 13.0214C 9.0105 13.4858 9.86011 13.4329 10.395 12.898L 14.2324 9.06067C 15.2087 8.08435 16.7915 8.08435 17.7678 9.06067L 19.0024 10.2952L 19.0024 2.67212C 19.0024 1.81 18.375 1.11115 17.6011 1.11115L 2.49414 1.11115ZM 0.997559 17.2221L 0.997559 15.2268L 4.08887 12.4789C 4.62939 11.9986 5.43555 11.9721 6.00635 12.4161L 7.79956 13.8107C 8.79468 14.5848 10.2107 14.4966 11.1021 13.6051L 14.9395 9.76776C 15.5254 9.18201 16.4751 9.18201 17.0608 9.76776L 19.0024 11.7095L 19.0024 17.2221C 19.0024 18.1426 18.3325 18.8889 17.5059 18.8889L 2.49414 18.8889C 1.66772 18.8889 0.997559 18.1426 0.997559 17.2221Z"
              ></path>
            </symbol>
            <symbol id="ic-send-message" viewBox="0 0 512 512">
              <path
                fill-rule="evenodd"
                d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"
              ></path>
            </symbol>
            <symbol id="ic-check" viewBox="0 0 12 9">
              <path
                fill-rule="evenodd"
                d="M 11.7985 0.111549C 12.0323 0.285046 12.0678 0.598796 11.8779 0.812329L 4.37336 8.84207C 4.17187 9.05766 3.83437 9.05172 3.6402 8.82917L 0.134965 4.8116C -0.0634062 4.60454 -0.0404331 4.28982 0.186277 4.10864C 0.412988 3.92746 0.757585 3.94845 0.955956 4.1555L 4 7.7013L 11.0312 0.184044C 11.2212 -0.0294899 11.5647 -0.0619468 11.7985 0.111549Z"
              ></path>
            </symbol>
            <symbol id="ic-double-check" viewBox="0 0 16 9">
              <path
                d="M 6.77422 6.27328L 6.08575 5.47144L 5.37432 6.23204L 6.06866 7.02799L 6.77422 6.27328Z"
              ></path>
              <path
                d="M 5.37432 7.77099L 4.37334 8.84207C 4.17193 9.05767 3.83453 9.05172 3.64019 8.82916L 0.135063 4.81161C -0.0634234 4.60454 -0.0404742 4.2898 0.186332 4.10861C 0.413139 3.92748 0.757621 3.94841 0.956108 4.15549L 4.00005 7.70129L 4.67974 6.97472L 5.37432 7.77099Z"
              ></path>
              <path
                d="M 6.78081 4.72832L 7.46905 5.52985L 11.878 0.812304C 12.0679 0.598776 12.0323 0.285033 11.7986 0.111553C 11.5648 -0.0619265 11.2212 -0.0295244 11.0313 0.184066L 6.78081 4.72832Z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M 15.7985 0.111549C 16.0323 0.285046 16.0678 0.598796 15.8779 0.812329L 8.37336 8.84207C 8.17187 9.05766 7.83437 9.05172 7.6402 8.82917L 4.13497 4.8116C 3.93659 4.60454 3.95957 4.28982 4.18628 4.10864C 4.41299 3.92746 4.75759 3.94845 4.95596 4.1555L 8 7.7013L 15.0312 0.184044C 15.2212 -0.0294899 15.5647 -0.0619468 15.7985 0.111549Z"
              ></path>
            </symbol>
            <symbol id="ic-reply" viewBox="0 0 13 16">
              <path
                id="path0_fill"
                d="M 4.00572 0.248674C 4.33264 -0.0828914 4.86267 -0.0828914 5.18959 0.248674C 5.5165 0.58024 5.5165 1.11781 5.18959 1.44938L 2.85812 3.814L 4.2841 3.814C 9.09776 3.814 13 7.77173 13 12.6538L 13 15.151C 13 15.6199 12.6252 16 12.1629 16C 11.7006 16 11.3258 15.6199 11.3258 15.151L 11.3258 12.6538C 11.3258 8.70954 8.17311 5.51205 4.2841 5.51205L 2.85812 5.51205L 5.18959 7.87667C 5.5165 8.20823 5.5165 8.7458 5.18959 9.07737C 4.86267 9.40893 4.33264 9.40893 4.00572 9.07737L 0.245183 5.26337C -0.0817275 4.93181 -0.0817275 4.39423 0.245183 4.06267L 4.00572 0.248674Z"
              ></path>
            </symbol>
          </svg>

          <!-- header title -->
          <div class="qcw-header" style="background: rgb(255, 255, 255)">
            <div class="qcw-header-avatar">
              <img :src="image.customer" alt="rw" />
            </div>
            <div class="qcw-header-info">
              <div>
                <div
                  class="qcw-user-display-name"
                  style="color: rgb(102, 102, 102)"
                >
                  {{ toHTML(getDialogActive.name) }}
                  <small v-if="threadIsSnoozed"
                    >( snooze : {{ threadIsSnoozed.timeDelay }}M)</small
                  >
                </div>
                <div
                  class="qcw-user-status qcw-user-status--group qismo-user-status--override"
                >
                  {{ getDialogActive.email }}
                </div>
              </div>
            </div>
            <div class="qcw-header__actions">
              <a
                v-b-tooltip.hover
                title="Find a message"
                class="link menu"
                href="javascript:void(0)"
                @click="toggleChatSearch()"
                ><i class="material-icons">search</i>
              </a>
              <a
                v-b-tooltip.hover
                title="Set Snooze"
                class="link menu"
                @click="snoozeChat()"
                href="javascript:void(0)"
                ><i class="material-icons">snooze</i>
              </a>
              <a
                class="link menu"
                href="javascript:void(0)"
                @click="toggleChatInfo()"
                ><i class="material-icons">info</i>
                <div class="tooltip top-center">User info</div>
              </a>
            </div>
          </div>

          <!-- search form field -->
          <div class="qcw-header search" v-if="getSearch.formOpen">
            <div class="search-form">
              <input
                ref="search"
                v-model="form.search"
                @keyup="search()"
                type="text"
                placeholder="Search by keyword ..."
              />
              <div class="clear-search">
                <i class="material-icons" @click="toggleChatSearch()">close</i>
              </div>
            </div>
          </div>

          <!--template for reply message-->
          <!--<div class="reply-wrapper reply-wrapper&#45;&#45;preview">
            <div>
              <div class="reply-sender">Addin</div>
              <div class="qcw-comment__content">baa asdfadsfa</div>
            </div>
            <i class="reply-close-btn"
              ><svg class="qc-icon"><use xlink:href="#ic-close"></use></svg
            ></i>
          </div>-->

          <!-- message list  -->
          <div class="qcw-comments isReading">
            <div class="inner">
              <ul>
                <!-- button load more messages -->
                <btn-load-more></btn-load-more>

                <!-- looping message -->
                <template v-for="(message, key) in messages">
                  <!-- message from system -->
                  <botPartBubble
                    :key="key"
                    v-if="message.actor === 'system'"
                    :message="message"
                  />

                  <!-- for customer -->
                  <client-part
                    :key="key"
                    :message="message"
                    :clientImage="image.customer"
                    :uuidCurrentOpen="getUuidOpened"
                    @removeMessage="removeMessageFromChild"
                    v-if="
                      message.actor === 'customer' ||
                      (message.actor === 'staff' && !isAgent(message.staffid))
                    "
                  ></client-part>

                  <!-- for staff -->
                  <agent-part
                    :key="key"
                    :message="message"
                    :agentImage="image.staff"
                    :uuidCurrentOpen="getUuidOpened"
                    v-if="message.actor === 'staff' && isAgent(message.staffid)"
                    @removeMessage="removeMessageFromChild"
                  ></agent-part>
                </template>
              </ul>
            </div>
          </div>

          <!-- form balasan -->
          <div class="qcw-comment-form">
            <i class="qcw-icon" v-b-tooltip.hover title="Attachment">
              <label id="iconAttach">
                <input
                  type="file"
                  class="uploader__input"
                  @change="onChooseFile"
                />
                <svg class="qc-icon" style="fill: rgb(151, 151, 151)">
                  <use xlink:href="#ic-attachment"></use>
                </svg>
              </label>
            </i>
            <i
              class="qcw-icon"
              @click="showEmoji = !showEmoji"
              v-b-tooltip.hover
              title="Emoji"
            >
              <label id="iconEmoji">
                <svg class="qc-icon" style="fill: rgb(151, 151, 151)">
                  <use xlink:href="#ic-smiley"></use>
                </svg>
              </label>
            </i>
            <div class="input-form" style="padding: 0">
              <textarea
                @paste="onPaste"
                @keydown.enter.exact.prevent
                @keyup.enter.exact="onKeyUpMessage"
                :value="writeMessage"
                @input="$emit('update:writeMessage', $event.target.value)"
                placeholder="Send a message ..."
                style="color: rgb(102, 102, 102)"
                :style="{ height: textareaHeight + 'px' }"
                ref="message"
              ></textarea>
            </div>
            <i
              @click="sendMessage()"
              style="cursor: pointer"
              v-b-tooltip.hover
              title="Send Message"
            >
              <svg
                class="qc-icon"
                :style="
                  writeMessage === ''
                    ? 'fill: rgb(151, 151, 151)'
                    : 'fill: rgb(0, 170, 229)'
                "
              >
                <use xlink:href="#ic-send-message"></use>
              </svg>
            </i>

            <div class="qcw-upload-toast"><span></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blankRoom from "@/components/staff/chat_area/BlankRoom";
import clientPart from "@/components/staff/chat_area/LeftPartMessage";
import agentPart from "@/components/staff/chat_area/RightPartMessage";
import botPartBubble from "@/components/staff/chat_area/BotPartBubble.vue";
import { VEmojiPicker } from "v-emoji-picker";
import btnLoadMore from "@/components/widget/BtnLoadMoreMessage";

import { image } from "@/common/config";
import { loader } from "@/common/mixins/mix_loader";
import { mapGetters } from "vuex";
import { staffCookies } from "@/common/mixins/mix_cookies";
import { agentMessage } from "@/common/mixins/mix_message";
import {
  alert,
  waktu,
  request,
  cloudStorage,
  util,
} from "@/common/mixins/mix_helper.js";
import $ from "jquery";
import { snooze } from "@/common/mixins/mix_localStorage";
import AppConfig from "@/common/config/app.config.json";

export default {
  mixins: [
    alert,
    waktu,
    loader,
    request,
    staffCookies,
    cloudStorage,
    agentMessage,
    snooze,
    util,
  ],
  components: {
    blankRoom,
    botPartBubble,
    clientPart,
    agentPart,
    VEmojiPicker,
    btnLoadMore,
  },
  props: {
    writeMessage: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      searchOpen: false,
      form: {
        search: "",
      },
      image: image,
      textareaHeight: 100,
      showEmoji: false,
    };
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit("update:writeMessage", this.writeMessage + " " + emoji.data);
      this.showEmoji = false;
      this.$refs.message.focus();
    },

    /**
     * --------------------------------------------------------------
     * user info
     * --------------------------------------------------------------
     */
    toggleChatInfo: function (show = null) {
      if (show === null) {
        this.$store.dispatch("toggleUserShow");
      } else {
        this.$store.dispatch("toggleUserShow", { show: show });
      }
    },

    /**
     * --------------------------------------------------------------
     * search keyword in staff | for toggle open search staff
     * --------------------------------------------------------------
     */
    toggleChatSearch: function () {
      let toggle = !this.getSearch.formOpen;

      this.form.search = "";
      if (toggle) {
        this.toggleChatInfo(true);
        // kasih delay, karna gk bisa baca dom
        setTimeout(() => {
          this.$refs.search.focus();
        }, 1000);
      }
      this.$store.dispatch("SearchMessage", {
        formOpen: toggle,
        keyword: "",
        result: [],
      });
    },

    /**
     * --------------------------------------------------------------
     * on enter keypress
     * --------------------------------------------------------------
     */
    onKeyUpMessage: function (e) {
      if (e.key === "Enter" && !e.shiftKey) {
        this.textareaHeight = 100;
        this.sendMessage();
      } else {
        if (this.textareaHeight < 130) {
          this.textareaHeight += 20.25;
        }
      }
    },

    /**
     * --------------------------------------------------------------
     * trigger button send message
     * --------------------------------------------------------------
     */
    sendMessage: function () {
      if (this.writeMessage.trim() === "") {
        this.$swal("Error", "Message can't be empty", "error");
      } else {
        let newMessage = this.mixGenerateMessage({
          message: this.writeMessage.trim(),
        });
        this.$emit("update:writeMessage", "");
        if (!newMessage) {
          this.$swal("Error", "Can't generate message", "error");
        } else {
          // check snooze jika ada untuk hilangkan indicator
          this.$store.dispatch("SnoozeRemoveAlert", {
            threadid: newMessage.threadid,
            removeSnooze: true,
          });
          this.mixSnoozeRemoveAlert(newMessage.threadid);

          newMessage["message"] = this.escape(newMessage.message);
          this.$store.dispatch("staffAppendNewMessage", newMessage);

          /**
           * --------------------------------------------------------------
           * scroll to end chat && remove elements li first chat
           * --------------------------------------------------------------
           */
          setTimeout(() => {
            this.scrollToBottomChat();

            this.$store.dispatch("removeFirstMessage");
          }, 200);

          /**
           * --------------------------------------------------------------
           * send message with API
           * --------------------------------------------------------------
           */
          this.$store
            .dispatch("staffSendMessage", {
              message: newMessage,
            })
            .then(() => {
              newMessage["status"] = "sent";
              this.getChat.filter((all, index) => {
                if (all.uuid === newMessage.uuid) {
                  this.$store.dispatch("staffMessageUpdate", {
                    index: index,
                    record: newMessage,
                  });
                }
              });

              /**
               * --------------------------------------------------------------
               * update thread
               * --------------------------------------------------------------
               */
              let threads = this.getThreads.all;
              let threadIsExist = threads.find(
                ({ id }) => id === newMessage.threadid
              );
              if (typeof threadIsExist !== "undefined") {
                threads.filter((thread, index) => {
                  if (thread.id === newMessage.threadid) {
                    this.$store.dispatch("DialogUpdate", {
                      index: index,
                      threadid: thread.id,
                      record: {
                        time: this.mixEpoch(),
                        reply: true,
                        unread: false,
                        message: newMessage.message,
                        alertAway: false,
                      },
                    });
                  }
                });
              }
            })
            .catch((err) => {
              this.$swal("Error", err.data.message, "error");
            })
            .finally(() => {
              this.$store.dispatch("DialogMove", { move: true });
            });
        }
      }
    },

    scrollToBottomChat: function () {
      let el = $(".qcw-comments.isReading");
      if (el.length) {
        el.scrollTop(parseInt(el[0].scrollHeight));
      }
    },

    /**
     * --------------------------------------------------------------
     * search keyword data in one conversation
     * --------------------------------------------------------------
     */
    search: function () {
      let keyword = this.form.search.toLowerCase();
      keyword = keyword.trim();
      let result = this.getChat.filter((msg) => {
        let lower = msg.message.toLowerCase();
        return lower.includes(keyword);
      });
      this.$store.dispatch("SearchMessage", {
        keyword: keyword,
        result: keyword === "" ? [] : result,
      });
    },

    onPaste: function (e) {
      let files = e.clipboardData.files;
      if (files.length) {
        this.onChooseFile(files[0], true);
      }
    },

    /**
     * --------------------------------------------------------------
     * upload image gan
     * --------------------------------------------------------------
     */
    onChooseFile: function (e, onPaste = false) {
      let attachment = onPaste ? e : e.target.files[0];
      let extFiles = AppConfig.ext_allowed.file;
      let extImages = AppConfig.ext_allowed.image;
      let allowedExt = [...extFiles, ...extImages];
      if (allowedExt.includes(attachment.type)) {
        let size = Math.round(attachment.size / 1024);
        if (size > 10240) {
          this.$swal({
            title: "Warning",
            html: `Ukuran attachment maksimal 10MB!`,
            icon: "warning",
          });
        } else {
          this.loading();
          this.$store
            .dispatch("staffSendMessageFile", {
              message: attachment,
              tipe: extImages.includes(attachment.type) ? "image" : "file",
            })
            .then(() => {
              this.$swal("Success", `Attachment has been sent.`, "success");
            })
            .catch((err) => {
              this.$swal("Error", err.message, "error");
            })
            .finally(() => {
              this.$store.dispatch("DialogMove", { move: true });
              this.loading(false);
            });
        }
      } else {
        // alert when fail
        let ext = attachment.type.split("/");
        ext = ext[1].split("."); // get last ext
        this.$swal({
          title: "Error",
          html: `.<b>${
            ext[ext.length - 1]
          }</b> extensions are not allowed! <br> \
	  			<small><b>PNG | JPEG | JPG | GIF | BMP | PDF</b></small>`,
          icon: "error",
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * just compare cookies with agent id
     * --------------------------------------------------------------
     */
    isAgent: function (agentid) {
      let roti = this.cookies.get();
      return parseInt(agentid) === parseInt(roti.staff.id);
    },

    /**
     * --------------------------------------------------------------
     * for snooze chat, snoze berupa notif desktop
     * --------------------------------------------------------------
     */
    snoozeChat: function () {
      this.toggleChatInfo(true); // jaga2 userinfo close
      this.$store.dispatch("SnoozeToggle", { open: true });
    },

    /**
     * --------------------------------------------------------------
     * remove message, trigger from child
     * --------------------------------------------------------------
     */
    removeMessageFromChild(params) {
      this.$swal({
        title: "Can't be restore",
        html: `Are you sure you want to delete this message ?<br>[ <i>${params.message}</i> ]`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#FF5C58",
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          if (typeof params.id !== "undefined") {
            this.loading();
            this.API.post(this.URL.message.delete, {
              id: params.id,
              message: params.message,
            })
              .then(({ data }) => {
                this.$swal(
                  "Success",
                  "The message has been deleted.",
                  "success"
                );

                /**
                 * --------------------------------------------------
                 * delete dialog message
                 * --------------------------------------------------
                 */
                const threadOpen = this.getDialogActive;
                const threads = this.getThreads.all;
                threads.filter((thread, index) => {
                  if (thread.id === threadOpen.id) {
                    if (thread.message === params.message) {
                      this.$store.dispatch("DialogUpdate", {
                        index: index,
                        threadid: thread.id,
                        record: {
                          message: "<i>pesan dihapus</i>",
                        },
                      });
                    }
                  }
                });

                /**
                 * --------------------------------------------------------------
                 * update local message
                 * --------------------------------------------------------------
                 */
                this.getChat.filter((all, index) => {
                  if (all.uuid === data.data.uuid) {
                    let payload = {
                      index: index,
                      record: { message: data.data.message, deleted: "1" },
                    };
                    if (["image", "file"].includes(all.tipe)) {
                      payload.record["tipe"] = "text";
                    }
                    this.$store.dispatch("staffMessageUpdate", payload);
                  }
                });

                /**
                 * --------------------------------------------------------------
                 * for hide tooltip more message
                 * --------------------------------------------------------------
                 */
                this.$store.dispatch("setUuidMessageMoreOpened", null);
              })
              .catch((error) => {
                let msg = this.handleCatchAxios("Remove Message", error, true);
                this.$swal("Error", msg, "error");
              })
              .finally(() => {
                this.loading(false);
              });
          } else {
            this.$swal(
              "Error",
              "ID message not found. Please refresh first or re-select thread.",
              "error"
            );
          }
        }
      });
    },
  },
  computed: {
    blankRoom: function () {
      return Object.keys(this.getDialogActive).length === 0;
    },
    ...mapGetters([
      "getDialogActive",
      "getChat",
      "getSearch",
      "getSnoozeActive",
      "getThreads",
      "getUuidOpened",
    ]),
    messages: function () {
      if (this.searchOpen) {
        // let recordLength = Object.keys(this.filter.record).length;
        return this.form.record; // .length !== 0 ? this.filter.record : [];
      } else {
        return this.getChat;
      }
    },

    /**
     * --------------------------------------------------------------
     * searching message triger klik
     * --------------------------------------------------------------
     */
    listenSelectMessage() {
      return this.$store.state.staffChat._searchMessage.selected;
    },

    /**
     * --------------------------------------------------------------
     * for check apakah thread masuk ke snooze
     * --------------------------------------------------------------
     */
    threadIsSnoozed: function () {
      let snooze = this.getSnoozeActive.record;
      return snooze.find((val) => val.threadId === this.getDialogActive.id);
    },

    /**
     * --------------------------------------------------------------
     * for trigger autofokus textarea sendMessage
     * --------------------------------------------------------------
     */
    listenFocusTextarea() {
      return this.getThreads.textareaFocus;
    },
  },
  watch: {
    /**
     * --------------------------------------------------------------
     * list jika staff searching, and click message di userinfo
     * --------------------------------------------------------------
     */
    listenSelectMessage: function (baru) {
      let el = $(`li#${baru.time}`);
      if (el.length) {
        let scrollPos = el.position().top;

        $(".qcw-comments.isReading").animate({
          scrollTop: scrollPos,
        });
        el.css({ background: "#e1e1e1" });
        setTimeout(() => {
          el.css({ background: "" });
        }, 1000);
      }
    },

    /**
     * --------------------------------------------------------------
     * reset message
     * set autofocus on text area
     * --------------------------------------------------------------
     */
    listenFocusTextarea(focus) {
      // use timeout karna tidak dapat mengimbangi membaca dom element
      setTimeout(() => {
        if (focus) {
          if (this.getThreads.textareaClear) {
            this.$emit("update:writeMessage", "");
          }

          if (typeof this.$refs.message !== "undefined") {
            this.$refs.message.focus();
          }
          setTimeout(() => {
            // for reset
            this.$store.dispatch("textareaFocus", { focus: false });
          }, 400);
        }
      }, 500);
    },
  },
  mounted() {
    if (this.writeMessage !== "") {
      this.form.message = this.writeMessage;
    }
  },
};
</script>

<style scoped lang="scss">
.app-chat-area .qcw-header .qcw-header__actions {
  justify-content: space-evenly;
}
.app-chat-area .qcw-header .qcw-header__actions a.link {
  justify-content: normal;
}

.emoji-picker {
  position: absolute;
  z-index: 20;
  bottom: 76px;
  left: 60px;
}

.qcw-comment-form {
  label#iconAttach {
    &:hover {
      svg {
        fill: #00aae5 !important;
      }
    }
  }
  label#iconEmoji {
    &:hover {
      svg {
        fill: #f7c32b !important;
      }
    }
  }
}
.input-form {
  border: none;
  textarea {
    border: 1px solid #c4c4c4;
    padding: 1em;
    &:focus {
      border: 1px solid #00aae5;
    }
  }
}
</style>
